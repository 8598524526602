:root{
    --indigo: #3e318f;
}

@font-face {
    font-family: Grtsk-QuidelOrtho-V;
    src: url("../assets/fonts/GrtskQuidelOrthoV.ttf") format("truetype");
    font-weight: normal;
    font-display: swap;
}

body,p,h1,h2,h3,h4,h5,h6{
    margin:0;
    font-family: "Grtsk-QuidelOrtho-V";
}

.no-result-msg{
    font: 16px/24px Grtsk-QuidelOrtho-V;
    letter-spacing: 0px;
    color: #34383C;
    opacity: 0.68;
}

.no-result-msg-left{
    margin-top: 95px;
}

.headerPlaceholder, .footerPlaceholder{
    display: flex;
    justify-content: center;
    align-items: center;
}


/* #SubMenu, .header-triangle-container, #red-hz-line,
.header-search, .orthoplus-section, .mega-menu, #hd-close-icon{
    display: none;
} */

#SubMenu, .header-triangle-container, #red-hz-line,
.header-search, .orthoplus-section, #hd-close-icon{
    display: none;
}



.loader-parent{
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 20000;
  background: rgba(0,0,0,0.5);
}

.loader {
  position:absolute;
  left: calc(50% - 40px);
  top: calc(50% - 40px);
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid rgb(239 33 60);
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}




.ss-clear-svg{
    position: absolute;
    top:12px;
    right: 15px;
    cursor: pointer;
}

.ss-search-svg{
    position: absolute;
    top:14px;
    left: 12px;
}

/* Layout  */

.sui-layout-header{
    display: none;
}



.sui-layout-body{
    background: #fff;
}

.sui-layout-body__inner{
    padding: 0;
}




/* Searchbox  */

.sui-search-box__text-input::placeholder { 
    
    color: #34383C !important;
    opacity: 1; 
    font-size: unset !important;
    line-height: unset !important;
}


.searchBox .sui-search-box__submit{
    display: none;
}

.searchBox .sui-search-box__text-input{
    padding: 14px 32px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #E4E7E4;
    border-radius: 4px;
    opacity: 1;
    font-family: Grtsk-QuidelOrtho-V;

}

.ss-vertical-line{
    width: 1px;
    transform: matrix(-1, 0, 0, -1, 0, 0);
    background: #ADC1C7 0% 0% no-repeat padding-box;
    opacity: 0.5;
}

/* Facets filter  */





.ss-filter-heading{
    text-transform: capitalize;
    text-align: left;
    font: 18px/22px Grtsk-QuidelOrtho-V;
    font-weight: 600;
    letter-spacing: 0px;
    color: #34383C;
    opacity: 1;
    margin-bottom: 35px;
}

.ss-all-filter{
    margin-bottom: 49px;
    display: flex;
    flex-direction: column;
}

.ss-filter-container{
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    display: -webkit-inline-box;
}

.ss-filter-text{
    text-align: left;
    font: 16px/24px Grtsk-QuidelOrtho-V;
    letter-spacing: 0px;
    color: #34383C;
    opacity: 1;
}

.ss-filter-input{
    margin-right: 11px;
    width: 18px;
    height: 18px;

}

.ss-filter-input:hover{
    background: #00000099 0% 0% no-repeat padding-box;
    opacity: 1;
}

.ss-hr-line{
    background: #ADC1C7 0% 0% no-repeat padding-box;
    opacity: 0.5;
    margin-bottom: 48px;
}

.ss-facet-more{
    margin-left: 10%;
    color: var(--indigo);
    cursor: pointer;
}



/* Result  */



.ss-result-thumbnail{
    width: 97px;
    height: 97px;
}

.ss-result-body{
    padding-left: 24px;
}

.ss-result-type{
    margin-bottom:8px;
    text-align: left;
    font: 14px/17px Grtsk-QuidelOrtho-V;
    font-weight: 600;
    letter-spacing: 0.02px;
    color: #34383C;
    text-transform: uppercase;
    opacity: 0.75;

    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

.ss-result-title{
    height: 50px;
    text-align: left;
    letter-spacing: 0px;
    color: #34383C;
    font: 18px/24px Grtsk-QuidelOrtho-V;
    opacity: 1;
    font-weight: 600;
    margin-bottom: 8px;
    overflow-wrap: anywhere;
    
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}


.sui-sorting__label{
    text-align: left;
    font: 18px/22px Grtsk-QuidelOrtho-V;
    font-weight: 600;
    letter-spacing: 0px;
    color: #34383C;
    text-transform: capitalize;
    opacity: 1;
}




.ss-search-term-container{
    margin-bottom: 6px;    
}

.ss-search-term{
    text-align: left;
    font: 24px/29px Grtsk-QuidelOrtho-V;
    font-weight: 600;
    letter-spacing: 0.01px;
    color: #34383C;
    text-transform: capitalize;
    opacity: 1;
}


.ss-total-results{
    text-align: left;
    font: 18px/22px Grtsk-QuidelOrtho-V;
    letter-spacing: 0px;
    color: #34383C;
    text-transform: capitalize;
    opacity: 1;
}



/* pagination  */

.rc-pagination-prev a, .rc-pagination-next a {
    text-decoration: none;
}

.ss-pagination{
    margin: 0;

}

.ss-pagination .rc-pagination-item-active{
    background: var(--indigo) 0% 0% no-repeat padding-box !important;
    border-radius: 50%;
}

.ss-pagination .rc-pagination-item.rc-pagination-item-active a{
    text-align: left;
    font: 16px/24px Grtsk-QuidelOrtho-V;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.ss-pagination .rc-pagination-item a{
    text-align: left;
    font: normal normal normal 16px/24px Grtsk-QuidelOrtho-V;
    letter-spacing: 0px;
    color: var(--indigo);
    opacity: 1;
}

.ss-pagination .rc-pagination-item:hover {
    border-color: unset;
    background: #fbf0f1 0% 0% no-repeat padding-box;
    border-radius: 50%;
}

.ss-pagination .rc-pagination-item a:hover {
    color: var(--indigo);
}

.ss-pagination .rc-pagination-item-active:hover a {
    color: #fff !important;
}

.sui-paging .rc-pagination-item:hover a {
    color: var(--indigo);
}

/* .ss-pagination .rc-pagination-prev, .ss-pagination .rc-pagination-next{
    display: none;
} */

.rc-pagination-prev a:after, .rc-pagination-next a:after{
    margin-top: -3px;
    font-size: 36px;
    color: var(--indigo);
}

.sui-layout-main-footer{
    justify-content: flex-end;
    margin-top: 43px;
}

.sui-select__control{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #ADC1C7;
    border-radius: 4px;
    opacity: 1;
}

.sui-select{
    font-family: Grtsk-QuidelOrtho-V;
}

.error-msg{
    text-align: center;
    margin-top: 50px;
    font-size: 20px;
    font-weight: 600;
}


/* Mobile  */
@media (max-width: 767px){

    .no-result-msg-right{
        margin-top: 145px;
        text-align: left;
    }

    .headerPlaceholder{
        height: 64px;
    }

    .footerPlaceholder{
        height: 300px;
    }

    #MegaMenu, #dropdown-search-section{
        display: none;
    }

    .header-container{
        height: 64px;
    }

    .sui-layout-sidebar-toggle, .sui-layout-sidebar-toggle{
        display: none;
    }

    .sui-layout {
        width: auto;
        padding: 96px 24px 96px 25px;
    }

    .sui-layout-main {
        width: 100%;
        padding: 0;
        /* padding-left: 32px; */
    }
    
    .sui-layout-sidebar{
        width: 100%;
        padding: 0;
        display: unset;
        position: unset;
    }

    .ss-searchbox-container{
        margin-bottom: 48px;
        width: 100%;
        position: relative;
    }

    .ss-facet-vl-container{
        display: unset;
    }

    .ss-vertical-line{
        display: none;
    }


    .dsk-pagination{
        display: none;
    }

    .tblt-pagination-container{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 14px;
    }

    .ss-facet-container{
        width: 100%;
        margin-right: 32px;
    }
    
    .ss-top-pagination-container{
        margin-bottom: 60px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    .ss-result-container{
        display: flex;
        cursor: pointer;
        background: #F3F6F7 0% 0% no-repeat padding-box;
        opacity: 1;
        width: 100%;
        margin-bottom: 24px;
        padding: 21px 12px 24px 24px;
    }

    
    .ss-result-tag{
        text-align: left;
        font: 14px/17px Grtsk-QuidelOrtho-V;
        letter-spacing: 0px;
        color: #34383CBF;
        opacity: 1;

        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
    }

}


/* tablet  */
@media (min-width:768px) and (max-width: 1199px){

    .no-result-msg-right{
        margin-top: 148px;
        text-align: left;
    }

    .headerPlaceholder{
        height: 64px;
    }

    .footerPlaceholder{
        height: 300px;
    }

    #MegaMenu, #dropdown-search-section{
        display: none;
    }

    .header-container{
        height: 64px;
    }

    .sui-layout-body__inner{
        display: flex;
    }

    .sui-layout-sidebar-toggle, .sui-layout-sidebar-toggle{
        display: none;
    }

    .sui-layout {
        width: auto;
        padding: 91px 33px 96px 33px;
    }

    .sui-layout-main {
        width: 68.2%;
        padding: 0;
        padding-left: 32px;
    }
    
    .sui-layout-sidebar{
        width: 47%;
        padding: 0;
        display: unset;
        position: unset;
    }

    .ss-searchbox-container{
        margin-bottom: 48px;
        width: 89.6%;
        position: relative;
    }

    .dsk-pagination{
        display: none;
    }

    .tblt-pagination-container{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 15px;
    }

    .ss-facet-vl-container{
        display: flex;
    }

    .ss-facet-container{
        width: 100%;
        margin-right: 32px;
    }
    
    .ss-top-pagination-container{
        margin-bottom: 70px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    .ss-result-container{
        display: flex;
        cursor: pointer;
        background: #F3F6F7 0% 0% no-repeat padding-box;
        opacity: 1;
        width: 100%;
        margin-bottom: 24px;
        padding: 21px 24px 24px 24px;
    }
    
    .ss-result-tag{
        text-align: left;
        font: 14px/17px Grtsk-QuidelOrtho-V;
        letter-spacing: 0px;
        color: #34383CBF;
        opacity: 1;

        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
    }


}



/* desktop */
@media (min-width: 1200px){

    .no-result-msg-right{
        margin-top: 148px;
        text-align: center;
    }

    .headerPlaceholder{
        height: 158px;
    }

    .footerPlaceholder{
        height: 300px;
    }

    .sui-layout {
        width: auto;
        padding: 123px 125px 96px 125px;
        max-width: 1366px;
        margin: auto;
    }

    .sui-layout-main {
        width: 68%;
        padding: 0;
        padding-left: 65px;
    }
    
    .sui-layout-sidebar{
        width: 39.2%;
        padding: 0;
    }

    .ss-searchbox-container{
        margin-bottom: 48px;
        width: 86.8%;
        position: relative;
    }

    .tblt-pagination{
        display: none;
    }

    .ss-facet-vl-container{
        display: flex;
    }

    .ss-facet-container{
        width: 100%;
        margin-right: 52px;
    }
    
    .ss-top-pagination-container{
        margin-bottom: 28px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    .ss-result-container{
        display: flex;
        cursor: pointer;
        background: #F3F6F7 0% 0% no-repeat padding-box;
        opacity: 1;
        width: 100%;
        margin-bottom: 24px;
        padding: 21px 48px 24px 24px;
    }

    
    .ss-result-tag{
        text-align: left;
        font: 14px/17px Grtsk-QuidelOrtho-V;
        letter-spacing: 0px;
        color: #34383CBF;
        opacity: 1;

        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
    }

}